/* eslint-disable camelcase, object-curly-newline, react/no-danger, react/no-array-index-key */
import React, { Component } from "react";
import styled from "styled-components";

const S = {
  Container: styled.div`
    color: black;
    width: 100%;
    position: relative;

    > div {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: calc(20px / 2);
      position: relative;
      height: auto;
    }

    &:not(:last-child) {
      margin-bottom: calc(20px * 4);
    }

    img {
      box-sizing: border-box;
      width: 100%;
      padding-top: calc(20px / 4);
      padding-bottom: calc(20px / 4);
      padding-left: 0;
      cursor: pointer;

      object-fit: cover;
      min-width: 100%;
    }

    iframe {
      min-width: calc(100%);
      height: 500px;
      margin-bottom: calc(20px / 2);
      outline: none;
      border: 0;
    }

    @media screen and (max-width: 768px) and (orientation: landscape) {
      iframe {
        height: 100vh;
      }
    }
    @media screen and (max-width: 768px) and (orientation: portrait) {
      iframe {
        height: 100vw;
      }
    }

    p.caption {
      margin-top: calc(20px / 4);
      margin-bottom: calc(20px / 4);
      padding: 0;
      grid-column: calc(1 / -1);
      font-size: 1rem;

      > p {
        margin: 0;
        line-height: 1.4rem;
      }
    }

    p.tags {
      margin-top: 0;
      margin-bottom: calc(20px / 2);
      padding: 0;
      font-size: 0.85rem;
      color: rgba(0, 0, 0, 0.66);
      grid-column: calc(1 / -1);
    }
  `,
};


class Post extends Component {
  render() {
    const {
      id,
      tags,
      maximize,
      layout,
      content,
      screenWidth,
    } = this.props;

    const hasLayout = layout && layout.length > 0

    const getHeight = (curr) => {
      const x = Object.keys(content?.[curr]?.media || {}).length > 8
      const v = Object.keys(content?.[curr]?.media || {}).length - (x ? 4 : 3)
      const h = content?.[curr]?.media?.[v >= 0 ? v : 0]?.height || 0
      return h
    }

    return (
      <S.Container className="Post">
        {hasLayout && (layout?.[0]?.display || []).map(({ blocks } = {}, i) => {
          if (!blocks) return null

          const minHeight = blocks.reduce((acc, curr) => {
            const h = getHeight(curr)
            return h < acc ? h : acc;
          }, Infinity)
          const diffHeight = Math.abs(blocks.reduce((acc, curr, j, arr) => {
            const h = getHeight(curr)
            if (arr.length === 1) return 0
            return acc === 0 ? h : acc - h
          }, 0))

          let responsiveMinHeight
          if (screenWidth === 0) responsiveMinHeight = parseInt(768 / 3, 10)
          if (screenWidth < 768) responsiveMinHeight = parseInt((768 / 3) - ((768 - screenWidth) / 3), 10)

          return (
            <div
              key={`${id}-${i}`}
              style={{
                width: {
                  1: "100%",
                  2: "calc(50% - 10px / 2)",
                  3: "calc(33.333% - (10px - (10px / 3)))",
                }[blocks.length] || 'auto',
              }}
            >
              {blocks.map((e, j) => {
                const item = content?.[e] || {}
                const { media_key, url, height } = item?.media?.[0] || {}

                if (item?.type === 'image') return <img
                  key={`${media_key}-${i}-${j}`}
                  src={url}
                  onClick={() => maximize(content.filter(({ type }) => type === 'image'), e)}
                  {...(blocks.length > 1 ? { height: (diffHeight === 0 ? 'auto' : height) } : {})}
                  {...(blocks.length > 1 ? { style: { maxHeight: `${Math.max(responsiveMinHeight, minHeight - ((Math.abs((screenWidth === 0 ? 0 : 768) - screenWidth) / blocks.length)))}px` } } : {})}
                  {...(diffHeight === 0 ? { style: { minHeight: 'initial' } } : {})}
                />
                if (item?.type === 'text') return <p
                  key={`${media_key}-${i}-${j}`}
                  className="caption"
                  dangerouslySetInnerHTML={{ __html: item?.text?.replace(/\n/g, '<br />') }}
                />
                return null
              })}
            </div>
          )
        })}
        {!hasLayout && (content || []).map((item, i) => {
          const { media_key, url } = item?.media?.[0] || {}

          if (item?.type === 'video') return (
            <iframe
              key={`${item?.url}-${i}`}
              src={(item?.url || '').replace('vimeo.com', 'player.vimeo.com/video')}
              height="300px"
            />
          );
          if (item?.type === 'image') return <img
            key={`${media_key}-${i}`}
            src={url}
            onClick={() => maximize(content.filter(({ type }) => type === 'image'), i)}
          />
          if (item?.type === 'text') return <p
            key={`${media_key}-${i}`}
            className="caption"
            dangerouslySetInnerHTML={{ __html: item?.text }}
          />
          return null
        })}
        {tags.length > 0 && (
          <p className="tags">
            {tags.reduce((acc, curr) => `${acc} #${curr}`, "")}
          </p>
        )}
      </S.Container>
    );
  }
}

export default Post;
