/* eslint-disable no-return-await, camelcase */
import React, { Component } from "react";
import classNames from "classnames";
import axios from "axios";
import styled from "styled-components";
import Post from "./Post";

const ENDPOINT = `${process.env.API_URL}/v2/blog/sytzewoudsma.tumblr.com/posts?api_key=${process.env.API_KEY}&npf=true`;

const S = {
  Container: styled.div`
    grid-area: posts;
    padding: 20px;
    max-width: 100vw;
    box-sizing: border-box;

    > p {
      margin: 0;
      margin-bottom: calc(20px / 1.5);
    }

    &.maximized {
      img {
        opacity: 0.2;
      }
    }

    div.overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      background: rgba(0, 0, 0, 0.9);
      pointer-events: all;
      cursor: pointer;

      img.maximized {
        position: fixed;
        top: 50%;
        left: 50%;
        opacity: 1;
        transform: translate(-50%, -50%);
        max-width: calc(100vw - calc(20px * 4));
        max-height: calc(100vh - calc(20px * 4));
        pointer-events: none;
      }
    }

    @media screen and (max-width: 768px) {
      div.overlay {
        img.maximized {
          max-width: calc(100vw - calc(20px * 2));
          max-height: calc(100vh - calc(20px * 2));
        }
      }
    }
  `,
};

class Posts extends Component {
  state = {
    screenWidth: window.innerWidth > 768 ? 0 : window.innerWidth,
    posts: [],
    next: undefined,
    content: [],
    image_url: undefined,
    image_index: undefined,
    maximized: false,
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.nextPage();
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  };

  nextPage = async () => {
    const { next, posts } = this.state;
    const url = posts.length === 0 && next === undefined ? ENDPOINT : next;
    return (
      (next || url) &&
      (await axios.get(url).then(({ data: { response } }) =>
        this.setState({
          posts: posts.concat(response.posts),
          next: response._links
            ? `${process.env.API_URL}${response._links.next.href}&api_key=${process.env.API_KEY}`
            : undefined,
        }),
      ))
    );
  };

  handleResize = () => {
    this.setState({
      screenWidth: window.innerWidth > 768 ? 0 : window.innerWidth,
    });
  };

  handleScroll = (e) => {
    if (document.body.scrollHeight - window.scrollY < 7500) {
      this.nextPage();
    }
  };

  nextImage = (e) => {
    if (![27, 37, 39].includes(e.keyCode)) return;

    if (e.keyCode === 27) return this.minimize();
    const { image_index, content } = this.state;
    const index =
      e.keyCode === 39
        ? (image_index + 1) % content.length
        : e.keyCode === 37 && image_index - 1 < 0
        ? content.length - 1
        : image_index - 1;
    this.setState({
      image_url: content[index].media?.[0].url,
      image_index: index,
    });
  };

  maximize = (content, index) => {
    window.addEventListener("keydown", this.nextImage);
    this.setState({
      content,
      image_url: content[index].media?.[0].url,
      image_index: index,
      maximized: true,
    });
  };

  minimize = () => {
    window.removeEventListener("keydown", this.nextImage);
    this.setState({
      content: [],
      image_url: undefined,
      image_index: undefined,
      maximized: false,
    });
  };

  render() {
    const { posts, maximized, image_url } = this.state;
    const postsClass = classNames("Posts", { maximized });

    return (
      <S.Container className={postsClass}>
        {posts.length && <p>Work</p>}
        {posts.map((post) => (
          <Post
            key={post.id}
            {...post}
            maximize={this.maximize}
            screenWidth={this.state.screenWidth}
          />
        ))}
        {maximized && image_url && (
          <div className="overlay" onClick={this.minimize}>
            <img className="maximized" src={image_url} />
          </div>
        )}
      </S.Container>
    );
  }
}

export default Posts;
