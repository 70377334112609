import React from "react";
import { render } from "react-dom";

import App from "./App";

render(<App />, document.getElementById("root"));

if (process.env.NODE_ENV === "development") {
  new EventSource("/esbuild").addEventListener("change", () =>
    location.reload(),
  );
}
