import React, { Component } from 'react'
import styled from 'styled-components'

const S = {
  Container: styled.div`
    grid-area: main;
    padding: 20px;

    h2 {
      margin: 0;
      font-family: 'Helvetica';
      font-size: 1.05rem;
      letter-spacing: 0.15px;
      line-height: 1.3rem;
      font-weight: normal;
    }

    > p {
      display: block;

      &:first-of-type {
        margin-top: 20px;

        span {
          display: block;
          margin-bottom: 1px;
        }
      }

      &:last-of-type {
        margin-bottom: 0;

        span {
          display: block;
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  `,
}

class Main extends Component {
  render() {
    const contact = ({ user, domain = window.location.hostname }) => {
      window.location.href = atob('bWFpbHRvOiV1c2VyJUBnJWRvbWFpbiUuY29t')
        .replace('%user%', user)
        .replace('%domain%', domain)
        .concat('?Subject=Hello')
      return false
    }

    return (
      <S.Container className="Main">
        <h2>Sytze Woudsma</h2>
        <p>
          <span>
            <a
              href="https://nl.linkedin.com/in/sytze-woudsma-884a74217"
              target="_blank"
            >
              LinkedIn
            </a>
          </span>
          <span>
            <a
              href="https://instagram.com/s_y_t_z?igshid=OGQ5ZDc2ODk2ZA=="
              target="_blank"
            >
              Instagram
            </a>
          </span>
          <span style={{ lineHeight: '24px' }}>
            Contact:&nbsp;
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                contact({ domain: 'mail', user: 'sytzewoudsma' })
              }}
              target="_top"
            >
              mail
            </a>
          </span>
        </p>
        <p>
          <span>
            Het werk wat ik maak gaat over de ruimte en afstand binnen de kaders
            van het doek. Binnen die kaders wordt er ruime gecreëerd voor
            experiment en interpretatie, zowel voor de maker als de toeschouwer.
          </span>
          <span>
            Macht is een onderliggend thema wat door verschillende werken naar
            voren komt, samen met fascinaties voor hiphop, graffiti en
            schilderkunst vormen deze de basis voor de beeldtaal die ik hanteer.
            Ik neem meer vrijheid in de manier waarop ik met materiaal omga en
            zie het maken van een werk als een visuele reis die zowel speels als
            doeltreffend is.
          </span>
          <span>
            In een samenleving waarin beelden steeds minder waarde krijgen door
            de explosieve toename van afbeeldingen die er dagelijks tot ons
            komen, zie ik de schilderkunst als een medium waarbinnen beeld zijn
            waardigheid kan behouden. Waar beelden kunnen shockeren en
            traumatiseren, of juist verstillen en een helende functie kunnen
            hebben.
          </span>
        </p>
      </S.Container>
    )
  }
}

export default Main
