import React, { Component } from "react";
import { createGlobalStyle } from "styled-components";

import Main from "./Main";
import Posts from "./Posts";

const GlobalStyle = createGlobalStyle`
  body {
    position: absolute;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: "Helvetica";
    font-size: 1.05rem;
    letter-spacing: 0.15px;
    line-height: 1.3rem;
    display: grid;
    justify-items: center;

    a {
      text-decoration: none;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1.5px solid;
      }
    }

    #root {
      display: grid;
      max-width: 768px;
      min-width: 768px;
      grid-template-columns: 100%;
      grid-template-areas:
        "main"
        "posts";

      @media screen and (max-width: 768px) {
        min-width: 100vw;
        font-size: 0.975rem;
        letter-spacing: 0.15px;
        grid-template-columns: 100%;
        grid-template-areas:
          "main"
          "posts";
      }
    }
  }
`;

class App extends Component {
  render() {
    return (
      <>
        <GlobalStyle />
        <Main />
        <Posts />
      </>
    );
  }
}

export default App;
